import React, { useEffect, useState } from 'react';
import { Table, Button, message, Modal } from 'antd';
import axios from 'axios';
import AddBrandsForm from '../components/AddBrandsForm';
import EditBrandForm from '../components/EditBrandForm';
import { useSelector } from 'react-redux';

const BrandsTable = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [brands, setBrands] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingBrand, setEditingBrand] = useState(null);
    const [loading, setLoading] = useState(true);


    // Get Brands
    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getBrands.php`);
            setBrands(response.data);
        } catch (error) {
            message.error('Failed to fetch brands');
        } finally {
            setLoading(false);
        }
    };

    // Edit Brands
    const showEditModal = (record) => {
        setEditingBrand(record);
        setIsEditModalOpen(true);
    };

    // Delete Brands
    const handleDelete = (id) => {
        showDeleteConfirm(id);
    };

    const deleteProduct = async (id) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/deleteBrand.php`, { id });
            message.success(response.data.message);
            fetchBrands();
        } catch (error) {
            message.error('Failed to delete brand');
        }
    };

    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Brands?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => deleteProduct(id),
        });
    };

    // Column
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Brand Name',
            dataIndex: 'brand_name',
            key: 'brand_name',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text) => <img src={`${API_BASE_URL}/${text}`} alt="brand" width={50} />,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="link" onClick={() => showEditModal(record)}>
                        Edit
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record.id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    const isMobile = useSelector((state) => state.mobile.isMobile);


    return (
        <>
            <Button style={{ marginBottom: '20px', display: 'block', marginLeft: 'auto' }} type="primary" onClick={() => setIsAddModalOpen(true)}>
                Add Brands
            </Button>

            <Table
                dataSource={brands}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />

            <Modal
                title="Add Brand"
                open={isAddModalOpen}
                onCancel={() => setIsAddModalOpen(false)}
                footer={null}
            >
                <AddBrandsForm fetchBrands={fetchBrands} onClose={() => setIsAddModalOpen(false)} />
            </Modal>

            {editingBrand && (
                <EditBrandForm
                    visible={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    brand={editingBrand}
                    fetchBrands={fetchBrands}
                />
            )}
        </>
    );
};

export default BrandsTable;
