import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button, message, InputNumber, Select, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { rto } from '../data/rto';
import { colours } from '../data/colours';
import { feature } from '../data/feature';

const EditProductForm = ({ visible, onClose, product, fetchProduct }) => {

    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [brands, setBrands] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            form.resetFields();
            form.setFieldsValue({
                product_name: product.product_name,
                price: product.price,
                description: product.description,
                make: product.make,
                model: product.model,
                variant: product.variant,
                manufacturing_year: product.manufacturing_year ? moment(product.manufacturing_year, 'YYYY') : null,
                registration_year: product.registration_year ? moment(product.registration_year, 'YYYY') : null,
                fuel_type: product.fuel_type,
                body_type: product.body_type,
                km_driven: product.km_driven,
                transmission: product.transmission,
                owners: product.owners,
                insurance_validity: product.insurance_validity ? moment(product.insurance_validity, 'YYYY-MM-DD') : null,
                insurance_type: product.insurance_type,
                rto: product.rto,
                colour: product.colour,
                ground_clearance: product.ground_clearance,
                boot_space: product.boot_space,
                seating_capacity: product.seating_capacity,
                fuel_tank_capacity: product.fuel_tank_capacity,
                max_power: product.max_power,
                features: product.features,
            });
        }
    }, [visible, product, form]);

    const handleEdit = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();
            formData.append('id', product.id);
            formData.append('product_name', values.product_name);
            formData.append('price', values.price);
            formData.append('description', values.description);
            formData.append('make', values.make);
            formData.append('model', values.model);
            formData.append('variant', values.variant);

            const manufacturingYear = values.manufacturing_year ? values.manufacturing_year.year() : '';
            formData.append('manufacturing_year', manufacturingYear);

            const registrationYear = values.registration_year ? values.registration_year.year() : '';
            formData.append('registration_year', registrationYear);

            formData.append('fuel_type', values.fuel_type);
            formData.append('body_type', values.body_type);
            formData.append('km_driven', values.km_driven);
            formData.append('transmission', values.transmission);
            formData.append('owners', values.owners);

            const insuranceValidity = values.insurance_validity ? values.insurance_validity.format('YYYY-MM-DD') : '';
            formData.append('insurance_validity', insuranceValidity);

            formData.append('insurance_type', values.insurance_type);
            formData.append('rto', values.rto);
            formData.append('colour', values.colour);
            formData.append('ground_clearance', values.ground_clearance);
            formData.append('boot_space', values.boot_space);
            formData.append('seating_capacity', values.seating_capacity);
            formData.append('fuel_tank_capacity', values.fuel_tank_capacity);
            formData.append('max_power', values.max_power);
            formData.append('features', JSON.stringify(values.features));

            if (values.image && values.image.length > 0) {
                values.image.forEach(file => {
                    formData.append('images[]', file.originFileObj);
                });
            }

            const response = await axios.post(`${API_BASE_URL}/editProduct.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.message) {
                message.success(response.data.message);
            } else {
                message.error('Failed to edit Product');
            }

            fetchProduct();
            onClose();

        } catch (error) {
            message.error('Failed to edit Product');
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/getBrands.php`);
            setBrands(response.data);
        } catch (error) {
            message.error('Failed to fetch brands');
        }
    };

    return (
        <Modal
            title="Edit Product"
            open={visible}
            onOk={handleEdit}
            onCancel={onClose}
            width={900}
        >
            <Form
                name="product_form"
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                  <Form.Item
                    name="product_name"
                    label="Product Name"
                    rules={[{ required: true, message: 'Please input the Product name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="image"
                    label="Images"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload
                        name="images"
                        listType="picture"
                        maxCount={5}
                        multiple={true}
                        beforeUpload={() => false}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Price"
                    name="price"
                    rules={[{ required: true, message: 'Please input the Price!' }]}
                >
                    <InputNumber placeholder='450000' />
                </Form.Item>

                <Form.Item
                    label="Description (Optional)"
                    name="description"
                    rules={[{ required: false, message: 'Please input the Description!' }]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item
                    label="Make (Brand)"
                    name="make"
                    rules={[{ required: true, message: 'Please input the Make!' }]}
                >
                    <Select placeholder="Select a make">
                        {brands.map((brand, index) => (
                            <Select.Option key={index} value={brand.brand_name}>{brand.brand_name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Model"
                    name="model"
                    rules={[{ required: true, message: 'Please input the Model!' }]}
                >
                    <Input placeholder='Creta' />
                </Form.Item>

                <Form.Item
                    label="Variant"
                    name="variant"
                    rules={[{ required: true, message: 'Please input the Variant!' }]}
                >
                    <Input placeholder='S PLUS KNIGHT 1.5 Manual' />
                </Form.Item>

                <Form.Item
                    label="Manufacturing Year"
                    name="manufacturing_year"
                    rules={[{ required: true, message: 'Please select the Manufacturing Year!' }]}
                >
                    <DatePicker picker="year" placeholder="Select manufacturing year" />
                </Form.Item>

                <Form.Item
                    label="Registration Year"
                    name="registration_year"
                    rules={[{ required: true, message: 'Please select the Registration Year!' }]}
                >
                    <DatePicker picker="year" placeholder="Select Registration Year" />
                </Form.Item>

                <Form.Item
                    label="Fuel Type"
                    name="fuel_type"
                    rules={[{ required: true, message: 'Please input the Fuel Type!' }]}
                >
                    <Select placeholder="Select a Fuel Type">
                        <Select.Option value="Petrol">Petrol</Select.Option>
                        <Select.Option value="Diesel">Diesel</Select.Option>
                        <Select.Option value="CNG">CNG</Select.Option>
                        <Select.Option value="Electric">Electric</Select.Option>
                        <Select.Option value="Petrol / CNG">Petrol / CNG</Select.Option>
                        <Select.Option value="Petrol / Hybrid">Petrol / Hybrid</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Body Type"
                    name="body_type"
                    rules={[{ required: true, message: 'Please input the Body Type!' }]}
                >
                    <Select placeholder="Select a Body Type">
                        <Select.Option value="Hatchback">Hatchback</Select.Option>
                        <Select.Option value="Sedan">Sedan</Select.Option>
                        <Select.Option value="SUV">SUV</Select.Option>
                        <Select.Option value="MUV">MUV</Select.Option>
                        <Select.Option value="Luxury Sedan">Luxury Sedan</Select.Option>
                        <Select.Option value="Luxury SUV">Luxury SUV</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="KM Driven"
                    name="km_driven"
                    rules={[{ required: true, message: 'Please input the KM Driven!' }]}
                >
                    <InputNumber placeholder='21500' />
                </Form.Item>

                <Form.Item
                    label="Transmission"
                    name="transmission"
                    rules={[{ required: true, message: 'Please input the Transmission!' }]}
                >
                    <Select placeholder="Select a Transmission">
                        <Select.Option value="Manual">Manual</Select.Option>
                        <Select.Option value="Automatic">Automatic</Select.Option>
                        <Select.Option value="iMT">iMT</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Number of Owners"
                    name="owners"
                    rules={[{ required: true, message: 'Please input the Number of Owners!' }]}
                >
                    <Select placeholder="Select a Number of Owners">
                        <Select.Option value="1st">1st</Select.Option>
                        <Select.Option value="2st">2st</Select.Option>
                        <Select.Option value="3st">3st</Select.Option>
                        <Select.Option value="4st">4st</Select.Option>
                        <Select.Option value="5st">5st</Select.Option>
                        <Select.Option value="5st+">5st+</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Insurance Validity"
                    name="insurance_validity"
                    rules={[{ required: true, message: 'Please select the Insurance Validity!' }]}
                >
                    <DatePicker picker="date" placeholder="Select Insurance Validity" />
                </Form.Item>

                <Form.Item
                    label="Insurance Type"
                    name="insurance_type"
                    rules={[{ required: true, message: 'Please select the Insurance Type!' }]}
                >
                    <Select placeholder="Select Insurance Type">
                        <Select.Option value="Third Party">Third Party</Select.Option>
                        <Select.Option value="Comprehensive">Comprehensive</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="RTO"
                    name="rto"
                    rules={[{ required: true, message: 'Please select the RTO!' }]}
                >
                    <Select
                        placeholder="Select RTO"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {rto &&
                            rto.map((rtos, index) => (
                                <Select.Option key={index} value={rtos}>{rtos}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Colour"
                    name="colour"
                    rules={[{ required: true, message: 'Please select the Colour!' }]}
                >
                    <Select
                        placeholder="Select Colour"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {colours &&
                            colours.map((color, index) => (
                                <Select.Option key={index} value={color}>{color}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Ground Clearance (mm)"
                    name="ground_clearance"
                    rules={[{ required: true, message: 'Please input the Ground Clearance!' }]}
                >
                    <InputNumber min={0} placeholder="190" />
                </Form.Item>

                <Form.Item
                    label="Boot Space (liters)"
                    name="boot_space"
                    rules={[{ required: true, message: 'Please input the Boot Space!' }]}
                >
                    <InputNumber min={0} placeholder="433" />
                </Form.Item>

                <Form.Item
                    label="Seating Capacity (person)"
                    name="seating_capacity"
                    rules={[{ required: true, message: 'Please input the Seating Capacity!' }]}
                >
                    <InputNumber min={1} placeholder="5" />
                </Form.Item>

                <Form.Item
                    label="Fuel Tank Capacity (liters)"
                    name="fuel_tank_capacity"
                    rules={[{ required: true, message: 'Please input the Fuel Tank Capacity!' }]}
                >
                    <InputNumber min={0} placeholder="50" />
                </Form.Item>

                <Form.Item
                    label="Max Power (bhp)"
                    name="max_power"
                    rules={[{ required: true, message: 'Please input the Max Power!' }]}
                >
                    <InputNumber min={0} placeholder="113" />
                </Form.Item>

                <Form.Item
                    label="Features"
                    name="features"
                    rules={[{ required: false, message: 'Please select at least one feature!' }]}
                >
                    <Select
                        mode="multiple"
                        placeholder="Select features"
                    >
                        {feature &&
                            feature.map((item, index) => (
                                <Select.Option key={index} value={item}>{item}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditProductForm;
