import React from 'react';
import { Layout, Dropdown, Avatar, Button } from 'antd';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';

const { Header } = Layout;

const AppHeader = ({ toggleSidebar, collapsed }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout())
  };

  const items = [
    {
      key: '0',
      label: <Link to={'/profile'} >Profile</Link>,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <Link onClick={handleLogout}>Logout</Link>,
    },
  ];

  return (
    <div>
      <Header className="site-layout-background" style={{ padding: 0, textAlign: 'right' }}>
        <Button type="text" icon={<MenuOutlined />} onClick={toggleSidebar} />
        <div style={{ flex: 1, textAlign: 'center' }}>
          <h1 style={{ color: '#fff', margin: 0 }}>
          <img src={`${process.env.PUBLIC_URL}/logo/white.png`} alt="logo" width={140} />
          </h1>
        </div>


        <Dropdown menu={{ items }} trigger={['click']}>
          <Link className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ marginRight: 16 }}>
            <Avatar icon={<UserOutlined />} />
          </Link>
        </Dropdown>
      </Header>
    </div>
  )
}

export default AppHeader;
