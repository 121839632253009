import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import axios from 'axios';
import DateFilter from '../filter/DateFilter';
import { useSelector } from 'react-redux';

const SellFormSubmition = () => {

    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);


    // Get Brands
    useEffect(() => {
        fetchSellFormData();
    }, []);

    const fetchSellFormData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getSellFormData.php`);
            console.log(response.data);
            if (response.data.status === 'success' && Array.isArray(response.data.data)) {
                const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setData(sortedData);
                setFilteredData(sortedData);
            } else {
                message.error('Unexpected response structure');
            }
        } catch (error) {
            message.error('Failed to fetch brands');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterApplied = (filteredData) => {
        setFilteredData(filteredData);
    };

    // Column
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            onCell: () => ({ style: { minWidth: 200 } }),
        },
        {
            title: 'RTO',
            dataIndex: 'rto',
            key: 'rto',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Manufacturing Year',
            dataIndex: 'manufacturing_year',
            key: 'manufacturing_year',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Fuel Type',
            dataIndex: 'fuel_type',
            key: 'fuel_type',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Transmission',
            dataIndex: 'transmission',
            key: 'transmission',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            key: 'variant',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Ownership',
            dataIndex: 'ownership',
            key: 'ownership',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Kilometers',
            dataIndex: 'kilometers',
            key: 'kilometers',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Want To Sell',
            dataIndex: 'want_to_sell',
            key: 'want_to_sell',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            onCell: () => ({ style: { minWidth: 250 } }),
        },

    ];

    const isMobile = useSelector((state) => state.mobile.isMobile);


    return (
        <>
            <DateFilter data={data} onFilterApplied={handleFilterApplied} />

            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />
        </>
    );
};

export default SellFormSubmition;
