import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import axios from 'axios';
import DateFilter from '../filter/DateFilter';
import { useSelector } from 'react-redux';

const Inquery = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [inquery, setInquery] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        fetchProduct(); 
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/getProducts.php`);
            if (response.data && Array.isArray(response.data)) {
                fetchInquery(response.data);
            } else {
                message.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            message.error(`Error fetching products: ${error.message}`);
        }
    };

    const fetchInquery = async (productData) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getInquery.php`);
            if (response.data.status === 'success') {
                const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                const enrichedInquery = sortedData.map((inqueryItem) => {
                    const matchingProduct = productData.find((prod) => prod.id === inqueryItem.productId);
                    return {
                        ...inqueryItem,
                        product_name: matchingProduct ? matchingProduct.product_name : 'N/A',
                        product_price: matchingProduct ? matchingProduct.price : 'N/A',
                        slug: matchingProduct ? matchingProduct.slug : 'N/A',
                    };
                });

                setInquery(enrichedInquery);
                setFilteredData(enrichedInquery);
            } else {
                message.error(`Failed to fetch inquiries: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error fetching inquiries:', error);
            message.error(`Error fetching inquiries: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterApplied = (filteredData) => {
        setFilteredData(filteredData);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            onCell: () => ({ style: { minWidth: 120 } }),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            onCell: () => ({ style: { minWidth: 250 } }),
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            onCell: () => ({ style: { minWidth: 200 } }),
        },
        {
            title: 'Product Price',
            dataIndex: 'product_price',
            key: 'product_price',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Date & Time',
            dataIndex: 'created_at',
            key: 'created_at',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <a
                    href={`https://safarcars.com/product/${record.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Product
                </a>
            ),
            onCell: () => ({ style: { minWidth: 150 } }),
        },
    ];

    const isMobile = useSelector((state) => state.mobile.isMobile);

    return (
        <>
            <DateFilter data={inquery} onFilterApplied={handleFilterApplied} />

            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />
        </>
    );
};

export default Inquery;
