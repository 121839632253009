import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import AppHeader from './Header';
import Sidebar from './Sidebar';
import { useSelector } from 'react-redux';

const { Content } = Layout;

const LayoutComponent = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true);
    const isMobile = useSelector((state) => state.mobile.isMobile);


    useEffect(() => {
        if (!collapsed && isMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [collapsed]);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <AppHeader toggleSidebar={toggleSidebar} collapsed={collapsed} />
            <Layout>
                <Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} />

                <Content style={{ margin: '24px 16px 24px', overflow: 'initial' }}>
                    <div className='layout-content'>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutComponent;
