import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, InputNumber, Select, DatePicker, Typography } from 'antd';
import axios from 'axios';
import { rto } from '../data/rto';
import { colours } from '../data/colours';
import moment from 'moment';


const { Title } = Typography;

const AddInvoiceForm = ({ onFinish, form, loadingBtn, selectedInvoice }) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);



    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/getBrands.php`);
            setBrands(response.data);
        } catch (error) {
            message.error('Failed to fetch brands');
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/getProducts.php`);
            setProducts(response.data);
        } catch (error) {
            message.error('Failed to fetch brands');
        }
    };

    useEffect(() => {
        fetchBrands();
        fetchProducts();
    }, []);

    useEffect(() => {
        if (selectedInvoice) {
            form.setFieldsValue({
                customer_name: selectedInvoice.customer_name,
                customer_email: selectedInvoice.customer_email,
                customer_mobile: selectedInvoice.customer_mobile,
                customer_address: selectedInvoice.customer_address,
                product_name: selectedInvoice.product_name,
                make: selectedInvoice.make,
                model: selectedInvoice.model,
                variant: selectedInvoice.variant,
                manufacturing_year: selectedInvoice.manufacturing_year ? moment(selectedInvoice.manufacturing_year) : null,
                registration_year: selectedInvoice.registration_year ? moment(selectedInvoice.registration_year) : null,
                fuel_type: selectedInvoice.fuel_type,
                km_driven: selectedInvoice.km_driven,
                transmission: selectedInvoice.transmission,
                owners: selectedInvoice.owners,
                insurance_validity: selectedInvoice.insurance_validity ? moment(selectedInvoice.insurance_validity) : null,
                insurance_type: selectedInvoice.insurance_type,
                rto: selectedInvoice.rto,
                colour: selectedInvoice.colour,
                chassis_no: selectedInvoice.chassis_no,
                engin_no: selectedInvoice.engin_no,
                price: selectedInvoice.price,
                tax: selectedInvoice.tax,
                other_price: selectedInvoice.other_price,
                discount: selectedInvoice.discount,
            });
        }
    }, [selectedInvoice, form]);

    const onProductChange = (value) => {
        console.log(value)
        const selectedProduct = products.find(product => product.id === value);
        if (selectedProduct) {
            form.setFieldsValue({
                product_name: selectedProduct.product_name,
                make: selectedProduct.make,
                model: selectedProduct.model,
                variant: selectedProduct.variant,
                manufacturing_year: selectedProduct.manufacturing_year ? moment(selectedProduct.manufacturing_year) : null,
                registration_year: selectedProduct.registration_year ? moment(selectedProduct.registration_year) : null,
                fuel_type: selectedProduct.fuel_type,
                km_driven: selectedProduct.km_driven,
                transmission: selectedProduct.transmission,
                owners: selectedProduct.owners,
                insurance_validity: selectedProduct.insurance_validity ? moment(selectedProduct.insurance_validity) : null,
                insurance_type: selectedProduct.insurance_type,
                rto: selectedProduct.rto,
                colour: selectedProduct.colour,
                price: selectedProduct.price,
            });
        }
    };

    return (
        <div>
            <Form
                form={form}
                name={"invoice_form"}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                id='invoice_form'
            >
                <Title level={3}>Bill To</Title>
                <Form.Item
                    label="Customer Name"
                    name="customer_name"
                    rules={[{ required: true, message: 'Please input the Customer name!' }]}
                >
                    <Input placeholder='John Doe' />
                </Form.Item>

                <Form.Item
                    label="Customer Email"
                    name="customer_email"
                    rules={[{ required: true, message: 'Please input the Customer Email!' }]}
                >
                    <Input placeholder='johndoe01@gmail.com' />
                </Form.Item>

                <Form.Item
                    label="Customer Mobile"
                    name="customer_mobile"
                    rules={[{ required: true, message: 'Please input the Customer Mobile!' }]}
                >
                    <Input placeholder='+91 1234 567 890' />
                </Form.Item>

                <Form.Item
                    label="Customer Address"
                    name="customer_address"
                    rules={[{ required: true, message: 'Please input the Customer Address!' }]}
                >
                    <Input placeholder='Ahmedabad, Gujarat, 363652' />
                </Form.Item>

                <Title level={3}>Product Details</Title>

                {!selectedInvoice &&
                    <Form.Item
                        label="Select Your Products (Optional)"
                        name="products"
                    >
                        <Select
                            placeholder="Select a Products"
                            onChange={onProductChange}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }>
                            {products.map((product, index) => (
                                <Select.Option key={index} value={product.id}>{product.product_name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                }

                <Form.Item
                    label="Product Name"
                    name="product_name"
                    rules={[{ required: true, message: 'Please input the Product name!' }]}
                >
                    <Input placeholder='Hyundai Creta S PLUS KNIGHT 1.5 Manual 2023' />
                </Form.Item>

                <Form.Item
                    label="Make (Brand)"
                    name="make"
                    rules={[{ required: true, message: 'Please input the Make!' }]}
                >
                    <Select placeholder="Select a make">
                        {brands.map((brand, index) => (
                            <Select.Option key={index} value={brand.brand_name}>{brand.brand_name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Model"
                    name="model"
                    rules={[{ required: true, message: 'Please input the Model!' }]}
                >
                    <Input placeholder='Creta' />
                </Form.Item>

                <Form.Item
                    label="Variant"
                    name="variant"
                    rules={[{ required: true, message: 'Please input the Variant!' }]}
                >
                    <Input placeholder='S PLUS KNIGHT 1.5 Manual' />
                </Form.Item>

                <Form.Item
                    label="Manufacturing Year"
                    name="manufacturing_year"
                    rules={[{ required: true, message: 'Please select the Manufacturing Year!' }]}
                >
                    <DatePicker picker="year" placeholder="Select manufacturing year" />
                </Form.Item>

                <Form.Item
                    label="Registration Year"
                    name="registration_year"
                    rules={[{ required: true, message: 'Please select the Registration Year!' }]}
                >
                    <DatePicker picker="year" placeholder="Select registration year" />
                </Form.Item>

                <Form.Item
                    label="Fuel Type"
                    name="fuel_type"
                    rules={[{ required: true, message: 'Please input the Fuel Type!' }]}
                >
                    <Select placeholder="Select a Fuel Type">
                        <Select.Option value="Petrol">Petrol</Select.Option>
                        <Select.Option value="Diesel">Diesel</Select.Option>
                        <Select.Option value="CNG">CNG</Select.Option>
                        <Select.Option value="Electric">Electric</Select.Option>
                        <Select.Option value="Petrol / CNG">Petrol / CNG</Select.Option>
                        <Select.Option value="Petrol / Hybrid">Petrol / Hybrid</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="KM Driven"
                    name="km_driven"
                    rules={[{ required: true, message: 'Please input the KM Driven!' }]}
                >
                    <InputNumber placeholder='21500' />
                </Form.Item>

                <Form.Item
                    label="Transmission"
                    name="transmission"
                    rules={[{ required: true, message: 'Please input the Transmission!' }]}
                >
                    <Select placeholder="Select Transmission">
                        <Select.Option value="Automatic">Automatic</Select.Option>
                        <Select.Option value="Manual">Manual</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Number of Owners"
                    name="owners"
                    rules={[{ required: true, message: 'Please input the Number of Owners!' }]}
                >
                    <Select placeholder="Select number of owners">
                        <Select.Option value="1st">1st</Select.Option>
                        <Select.Option value="2nd">2nd</Select.Option>
                        <Select.Option value="3rd">3rd</Select.Option>
                        <Select.Option value="4th">4th</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Insurance Validity"
                    name="insurance_validity"
                    rules={[{ required: true, message: 'Please input the Insurance Validity!' }]}
                >
                    <DatePicker placeholder="Select insurance validity" />
                </Form.Item>

                <Form.Item
                    label="Insurance Type"
                    name="insurance_type"
                    rules={[{ required: true, message: 'Please input the Insurance Type!' }]}
                >
                    <Select placeholder="Select insurance type">
                        <Select.Option value="Comprehensive">Comprehensive</Select.Option>
                        <Select.Option value="Third Party">Third Party</Select.Option>
                        <Select.Option value="Own Damage">Own Damage</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="RTO"
                    name="rto"
                    rules={[{ required: true, message: 'Please input the RTO!' }]}
                >
                    <Select placeholder="Select RTO">
                        {rto.map((rtoData, index) => (
                            <Select.Option key={index} value={rtoData}>{rtoData}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Colour"
                    name="colour"
                    rules={[{ required: true, message: 'Please input the Colour!' }]}
                >
                    <Select placeholder="Select Colour">
                        {colours.map((colour, index) => (
                            <Select.Option key={index} value={colour}>{colour}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Chassis Number"
                    name="chassis_no"
                    rules={[{ required: true, message: 'Please input the Chassis Number!' }]}
                >
                    <Input placeholder='ASFDASDFSS65254' />
                </Form.Item>

                <Form.Item
                    label="Engin Nomber"
                    name="engin_no"
                    rules={[{ required: true, message: 'Please input the Engin Number!' }]}
                >
                    <Input placeholder='ASFDASDFSS65254' />
                </Form.Item>

                <Title level={3}>Price</Title>

                <Form.Item
                    label="Price"
                    name="price"
                    rules={[{ required: true, message: 'Please input the Price!' }]}
                >
                    <InputNumber placeholder='1000000' />
                </Form.Item>

                <Form.Item
                    label="Tax (₹)"
                    name="tax"
                >
                    <InputNumber placeholder='5000' />
                </Form.Item>

                <Form.Item
                    label="Other Price (₹)"
                    name="other_price"
                >
                    <InputNumber placeholder='1500' />
                </Form.Item>

                <Form.Item
                    label="Discount (₹)"
                    name="discount"
                >
                    <InputNumber placeholder='2000' />
                </Form.Item>

                <Form.Item label >
                    <Button type="primary" htmlType="submit" loading={loadingBtn} block>
                        {selectedInvoice ? 'Update Invoice' : 'Add Invoice'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddInvoiceForm;
