import React from 'react';
import { Form, Input, Button, message, Typography, Card } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;


const RagisterOtpVerification = ({ email }) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const handleVerifyOtp = async (values) => {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('otp', values.otp);
        try {
            const response = await axios.post(`${API_BASE_URL}/adminRagisterOtpVerify.php`, formData);
            if (response.data.status === 'success') {
                message.success(response.data.message);
                navigate('/login')
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('An error occurred while verifying OTP.');
        }
    };

    return (
        <div className="authform-container">
            <Card className="authform-card">
            <Title level={3} style={{ textAlign: 'center' }}>Verify Account</Title>

                <Form form={form} onFinish={handleVerifyOtp} layout="vertical">

                    <Form.Item 
                    name="otp" 
                    label="OTP" 
                    rules={[{ required: true, message: 'Please enter the OTP!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item style={{ marginTop: '40px' }}>
                        <Button type="primary" htmlType="submit" block>Verify OTP</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default RagisterOtpVerification;
