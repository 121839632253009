import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import 'antd/dist/reset.css';
import Home from './pages/Home';
import Product from './pages/Product';
import Brand from './pages/Brand';
import './App.css'
import SellFormSubmition from './pages/SellFormSubmition';
import Employee from './pages/Employee';
import Contacts from './pages/Contact';
import Inquery from './pages/Inquery';
import Ragister from './auth/Ragister';
import Login from './auth/Login';
import VerifyAccount from './auth/VerifyAccount';
import ForgotPassword from './auth/ForgotPassword';
import Profile from './auth/Profile';
import ChangePassword from './auth/ChangePassword';
import InvoiceList from './pages/InvoiceList';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile } from './redux/mobileSlice';
import { logout } from './redux/authSlice';


function App() {

  const dispatch = useDispatch();

  useEffect(() => {
      const handleResize = () => {
          dispatch(setIsMobile(window.innerWidth <= 768));
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, [dispatch]);

  const userId = useSelector((state) => state.auth.userId);
  useEffect(() => {
    if (!userId) {
      dispatch(logout())
    }
  },[])


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/ragister" element={<Ragister />} />
          <Route path="/verifyaccount" element={<VerifyAccount />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          <Route path="/" element={<PrivateRoute component={Home} />} />
          <Route path="/product" element={<PrivateRoute component={Product} />} />
          <Route path="/brand" element={<PrivateRoute component={Brand} />} />
          <Route path="/sellfromsubmition" element={<PrivateRoute component={SellFormSubmition} />} />
          <Route path="/employee" element={<PrivateRoute component={Employee} />} />
          <Route path="/contacts" element={<PrivateRoute component={Contacts} />} />
          <Route path="/inquerys" element={<PrivateRoute component={Inquery} />} />
          <Route path="/invoicelist" element={<PrivateRoute component={InvoiceList} />} />

          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
          <Route path="/changepassword" element={<PrivateRoute component={ChangePassword} />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
