import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Typography } from 'antd';
import axios from 'axios';
import RagisterOtpVerification from './RagisterOtpVerification ';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const Ragister = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [form] = Form.useForm();
    const [email, setEmail] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);

    const handleRegister = async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('mobile', values.mobile);
        formData.append('address', values.address);
        formData.append('password', values.password);
        formData.append('confirm_password', values.confirm_password);
        try {
            const response = await axios.post(`${API_BASE_URL}/adminRagister.php`, formData);
            if (response.data.status === 'success') {
                message.success(response.data.message);
                setEmail(values.email);
                setIsOtpSent(true);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('An error occurred while registering.');
        }
    };

    return (
        <>
            <div className="authform-container">
                {!isOtpSent ? (
                    <Card className="authform-card">
                        <>
                            <Title level={3} style={{ textAlign: 'center' }}>Register</Title>

                            <Form
                                form={form}
                                onFinish={handleRegister}
                                layout="vertical"
                            >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{ required: true, message: 'Please enter your name!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: 'Please input your email!' },
                                        { type: 'email', message: 'The input is not valid E-mail!' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="mobile"
                                    label="Mobile"
                                    rules={[
                                        { required: true, message: 'Please input your mobile number!' },
                                        { len: 10, message: 'Mobile number must be 10 digits!' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <Input.TextArea />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                        { min: 8, message: 'Password must be Minimum 8 digits!' }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="confirm_password"
                                    label="Confirm Password"
                                    rules={[
                                        { required: true, message: 'Please confirm your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Text type="secondary">
                                    Note: If you are registering a new account, an OTP will be sent to the administrator's email address <b>safarcars2024@gmail.com</b> for account verification. If your account is not verified, you will not be able to access the admin panel.
                                </Text>

                                <Form.Item style={{ marginTop: '40px' }}>
                                    <Button type="primary" htmlType="submit" block>Register</Button>
                                </Form.Item>

                            </Form>
                        </>
                        <Text type="secondary">
                            Already registered? <Link to={'/login'}>Login Now</Link>
                        </Text>
                    </Card>
                ) : (
                    <RagisterOtpVerification email={email} />
                )}

            </div>
        </>
    );
};


export default Ragister;
