import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
    CloseOutlined,
    ContactsOutlined,
    DashboardOutlined,
    FormOutlined,
    QuestionCircleOutlined,
    ShoppingOutlined,
    TagsOutlined,
    UsergroupAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

const items = [
    {
        key: '/',
        icon: <DashboardOutlined />,
        label: <Link to="/">Dashboard</Link>,
    },
    {
        key: '/product',
        icon: <ShoppingOutlined />,
        label: <Link to="/product">Product</Link>,
    },
    {
        key: '/brand',
        icon: <TagsOutlined />,
        label: <Link to="/brand">Brand</Link>,
    },
    {
        key: '/sellfromsubmition',
        icon: <FormOutlined />,
        label: <Link to="/sellfromsubmition">Sell Form Submission</Link>,
    },
    // {
    //     key: '/employee',
    //     icon: <UsergroupAddOutlined />,
    //     label: <Link to="/employee">Employee Form Submission</Link>,
    // },
    {
        key: '/contacts',
        icon: <ContactsOutlined />,
        label: <Link to="/contacts">Contact Form Submission</Link>,
    },
    {
        key: '/inquerys',
        icon: <QuestionCircleOutlined />,
        label: <Link to="/inquerys">Inquiry</Link>,
    },
    {
        key: '/invoicelist',
        icon: <UserOutlined  />,
        label: <Link to="/invoicelist">Invoice List</Link>,
    },

];

const Sidebar = ({ collapsed, toggleSidebar }) => {
    const location = useLocation();

    const isMobile = useSelector((state) => state.mobile.isMobile);


    return (
        <div>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={toggleSidebar}
                breakpoint="lg"
                collapsedWidth={isMobile ? 0 : 80}
                className={isMobile ? (collapsed ? 'mobile-sider-collapsed' : 'mobile-sider-expanded') : ''}
                style={{
                    minHeight: '100%',
                    position: isMobile ? 'absolute' : 'relative',
                }}
            >

                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={items}
                    onClick={isMobile ? toggleSidebar : null}
                />
                <div style={{ height: isMobile ? '100px' : 'auto' }}></div>
            </Sider>
        </div>
    );
};

export default Sidebar;
