import axios from 'axios';
import { Button, Card, Descriptions, message, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setUserData } from '../redux/authSlice';

const { Title } = Typography;


const Profile = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const userId = useSelector((state) => state.auth.userId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId) {
            fetchUserData(userId);
        }
    }, [userId]);

    const fetchUserData = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/adminGetCurrentUserData.php`, {
                params: { id },
            });
            if (response.data.status === 'success') {
                setData(response.data.data);
                dispatch(setUserData(response.data.data))
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('Failed to fetch user data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Card style={{ width: '600px' }}>
                {loading && <p>Loading...</p>}
                {data ? (
                    <>
                        <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>Welcome, {data.name}</Title>
                        <Descriptions size='large' column={1} bordered>
                            <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
                            <Descriptions.Item label="Mobile">{data.mobile}</Descriptions.Item>
                            <Descriptions.Item label="Address">{data.address && data.address}</Descriptions.Item>
                            <Descriptions.Item label="Verified">{data.verified ? 'Yes' : 'No'}</Descriptions.Item>
                            <Descriptions.Item label="Password">
                                ********
                                <Link to={'/changepassword'}>
                                    <Button type='primary'>Change Password</Button>
                                </Link>
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                ) : (
                    !loading && <p>No user data available.</p>
                )}
            </Card>
        </div>
    );
};

export default Profile;
