import {
  CopyOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Button, Descriptions, message, Typography } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";

const { Text } = Typography;

const Invoice = ({ selectedInvoice }) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [invoices, setInvoices] = useState([]);
  const [loadingD, setLoadingD] = useState(false);
  const [loadingG, setLoadingG] = useState(false);

  //   Get Invoice
  const getInvoices = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getInvoices.php`);

      if (selectedInvoice?.id) {
        const selectedInvoiceData = response.data.find(
          (invoice) => invoice.id === selectedInvoice.id
        );

        setInvoices(selectedInvoiceData);
      } else {
        setInvoices(response.data);
      }
    } catch (error) {
      console.error("Error fetching invoices", error);
    }
  };

  useEffect(() => {
    getInvoices();
  }, [selectedInvoice]);

  // Download PDF
  const invoiceRef = useRef();

  const handleDownloadPDF = () => {
    setLoadingD(true);
    const input = invoiceRef.current;
    setTimeout(() => {
      html2canvas(input, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`invoice_${selectedInvoice.invoice_number}.pdf`);
        setLoadingD(false);
      });
    }, 100);
  };

  // Gonerate PDF Link
  const handleGenerateAndUploadPDF = async () => {
    setLoadingG(true);
    const input = invoiceRef.current;
    try {
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, `${selectedInvoice.invoice_number}.pdf`);
      formData.append("invoice_id", selectedInvoice.id);

      const response = await axios.post(
        `${API_BASE_URL}/invoicePdfLinkGonerate.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        message.success("Invoice Link Added");
        getInvoices();
        setLoadingG(false);
      } else {
        message.error(response.data.message);
        setLoadingG(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoadingG(false);
    }
  };

  // Copy Link
  const handleCopyLink = () => {
    if (invoices && invoices.invoice_link) {
      navigator.clipboard.writeText(invoices.invoice_link);
      message.success("Link copied to clipboard!");
    }
  };

  // Send Whatsapp
  const handleShareWhatsApp = () => {
    if (invoices && invoices.invoice_link && invoices.customer_mobile) {
      const messageText = `Here is your invoice link: ${invoices.invoice_link}`;
      const whatsappUrl = `https://wa.me/${
        invoices.customer_mobile
      }?text=${encodeURIComponent(messageText)}`;
      window.open(whatsappUrl, "_blank");
      message.info("Opening WhatsApp...");
    } else {
      message.error("Customer mobile number is missing!");
    }
  };

  // Send Email
  const handleShareEmail = () => {
    if (invoices && invoices.invoice_link && invoices.customer_email) {
      const subject = "Your Invoice Link";
      const body = `Here is your invoice link: ${invoices.invoice_link}`;
      const mailtoUrl = `mailto:${
        invoices.customer_email
      }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body
      )}`;
      window.location.href = mailtoUrl;
      message.info("Opening email client...");
    } else {
      message.error("Customer email address is missing!");
    }
  };

  return (
    <>
      <div style={{marginBottom:'20px'}}>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Button loading={loadingD} type="primary" onClick={handleDownloadPDF}>
            Download Invoice
          </Button>
          <Button loading={loadingG} type="primary" onClick={handleGenerateAndUploadPDF}>
            {invoices.invoice_link ? "Update Link" : "Gonerate Invoice Link"}
          </Button>
        </div>
        {invoices?.invoice_link && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={handleCopyLink}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                }}
              >
                Copy Link
              </Button>

              <Button
                type="primary"
                icon={<WhatsAppOutlined />}
                onClick={handleShareWhatsApp}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#25D366",
                  borderColor: "#25D366",
                }}
              >
                Share on WhatsApp
              </Button>

              <Button
                type="primary"
                icon={<MailOutlined />}
                onClick={handleShareEmail}
                style={{ backgroundColor: "#f5222d", borderColor: "#f5222d" }}
              >
                Share via Email
              </Button>
            </div>
            <Text
              style={{
                textAlign: "center",
                display: "block",
                color: "gray",
                marginTop: "10px",
              }}
            >
              Please make sure that the link has been updated before sharing it.
            </Text>
          </>
        )}
      </div>

      <div className="invoice-pdf">
        {invoices ? (
          <div ref={invoiceRef} className="invoice-container">
            <div className="invoice-details">
              <div className="left-section">
                <p>
                  Invoice No : <b>{invoices.invoice_number}</b>
                </p>
                <p>
                  Date : <b>{invoices.created_at} </b>
                </p>
              </div>
              <div className="right-section">
                <h3>{invoices.customer_name}</h3>
                <p>{invoices.customer_mobile}</p>
                <p>{invoices.customer_email}</p>
                <p>{invoices.customer_address}</p>
              </div>
            </div>
            <hr />
            <div className="product-details">
              <h2>Product Details</h2>
              <Descriptions
                bordered
                column={2}
                size="small"
                labelStyle={{ width: "25%", color: "#000", fontWeight: "500" }}
                contentStyle={{ width: "25%" }}
                style={{ width: "100%" }}
              >
                <Descriptions.Item label="Car Name">
                  {invoices.product_name}
                </Descriptions.Item>
                <Descriptions.Item label="Brand">
                  {invoices.make}
                </Descriptions.Item>
                <Descriptions.Item label="Model">
                  {invoices.model}
                </Descriptions.Item>
                <Descriptions.Item label="Variant">
                  {invoices.variant}
                </Descriptions.Item>
                <Descriptions.Item label="RTO">
                  {invoices.rto}
                </Descriptions.Item>
                <Descriptions.Item label="Colour">
                  {invoices.colour}
                </Descriptions.Item>
                <Descriptions.Item label="Insurance Validity">
                  {invoices.insurance_validity}
                </Descriptions.Item>
                <Descriptions.Item label="Manufacturing Year">
                  {invoices.manufacturing_year}
                </Descriptions.Item>
                <Descriptions.Item label="Fuel Type">
                  {invoices.fuel_type}
                </Descriptions.Item>
                <Descriptions.Item label="KM Driven">
                  {invoices.km_driven}
                </Descriptions.Item>
                <Descriptions.Item label="Transmistion">
                  {invoices.transmission}
                </Descriptions.Item>
                <Descriptions.Item label="Number Of Owners">
                  {invoices.owners}
                </Descriptions.Item>
                <Descriptions.Item label="Chassis Number">
                  {invoices.chassis_no}
                </Descriptions.Item>
                <Descriptions.Item label="Engine Number">
                  {invoices.engin_no}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="payment-details">
              <Descriptions
                bordered
                column={1}
                size="small"
                labelStyle={{
                  width: "25%",
                  color: "#000",
                  fontWeight: "500",
                  backgroundColor: "rgba(0,255,0,0.07)",
                }}
                contentStyle={{
                  width: "25%",
                  backgroundColor: "rgba(0,255,0,0.07)",
                }}
                style={{ width: "50%" }}
              >
                <Descriptions.Item label="Car Price">
                  {invoices.price}
                </Descriptions.Item>
                <Descriptions.Item label="Tax">
                  {invoices.tax}
                </Descriptions.Item>
                <Descriptions.Item label="Other Price">
                  {invoices.other_price}
                </Descriptions.Item>
                <Descriptions.Item label="Discount">
                  {invoices.discount}
                </Descriptions.Item>
                <Descriptions.Item
                  style={{
                    backgroundColor: "green",
                    color: "#fff",
                    fontSize: "20px",
                  }}
                  label="Total"
                >
                  {selectedInvoice
                    ? Number(selectedInvoice.price || 0) +
                      Number(selectedInvoice.tax || 0) +
                      Number(selectedInvoice.other_price || 0) -
                      Number(selectedInvoice.discount || 0)
                    : ""}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        ) : (
          <h2>Invoice Data Not Found</h2>
        )}
      </div>
    </>
  );
};

export default Invoice;
