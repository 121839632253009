import React, { useEffect } from 'react';
import { Modal, Form, Input, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const EditBrandForm = ({ visible, onClose, brand, fetchBrands }) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    
    const [form] = Form.useForm();

    useEffect(() => {
        // Form fields ko brand data ke hisaab se update karen
        form.setFieldsValue({
            brand_name: brand.brand_name,
            image: brand.image ? [{ url: `https://safarcars.com/api/${brand.image}` }] : [],
        });
    }, [brand, form]);

    const handleEdit = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();
            formData.append('id', brand.id);
            formData.append('brand_name', values.brand_name);

            // Check if the image field has a file and append the actual file object
            if (values.image && values.image.length > 0) {
                formData.append('image', values.image[0].originFileObj);
            }

            const response = await axios.post(`${API_BASE_URL}/editBrand.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.message) {
                message.success(response.data.message);
            } else {
                message.error('Failed to edit brand');
            }

            fetchBrands();
            onClose();
        } catch (error) {
            message.error('Failed to edit brand');
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Modal
            title="Edit Brand"
            open={visible}
            onOk={handleEdit}
            onCancel={onClose}
        >
            <Form form={form} layout="vertical" id="edit_brand">
                <Form.Item
                    name="brand_name"
                    label="Brand Name"
                    rules={[{ required: true, message: 'Please input the brand name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="image"
                    label="Image"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload
                        name="image"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={() => false}
                    >
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditBrandForm;
