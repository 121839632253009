import { Button, Card, Form, Input, message, Typography } from 'antd';
import React, { useState } from 'react'
import RagisterOtpVerification from './RagisterOtpVerification ';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;


const VerifyAccount = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [form] = Form.useForm();
    const [email, setEmail] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);

    const handleRegister = async (values) => {
        const formData = new FormData();
        formData.append('email', values.email);
        try {
            const response = await axios.post(`${API_BASE_URL}/adminResendOtp.php`, formData);
            if (response.data.status === 'success') {
                message.success(response.data.message);
                setEmail(values.email);
                setIsOtpSent(true);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('An error occurred while registering.');
        }
    };

    return (
        <>
            <div className="authform-container">
                <Card className="authform-card">
                    {!isOtpSent ? (
                        <>
                            <Title level={3} style={{ textAlign: 'center' }}>Verify Account</Title>

                            <Form form={form} onFinish={handleRegister} layout="vertical">

                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true, message: 'Please input your email!' },
                                        { type: 'email', message: 'The input is not valid E-mail!' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item style={{ marginTop: '40px' }}>
                                    <Button type="primary" htmlType="submit" block>Send OTP</Button>
                                </Form.Item>

                            </Form>
                        </>
                    ) : (
                        <RagisterOtpVerification email={email} />
                    )}

                    <Text type="secondary">
                        You Are Not Ragistered ? <Link to={'/ragister'}>Ragister Now</Link>
                    </Text>
                </Card>
            </div>
        </>
    );
};


export default VerifyAccount
