import React, { useEffect, useState } from 'react';
import { Table, Button, message, Modal } from 'antd';
import axios from 'axios';
import DateFilter from '../filter/DateFilter';
import { useSelector } from 'react-redux';

const Employee = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    // Get Employees
    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getEmployee.php`);
            if (response.data.status === 'success') {
                setEmployees(response.data.data);
                setFilteredData(response.data.data)
            } else {
                message.error('Failed to fetch employees');
            }
        } catch (error) {
            message.error('Error fetching employees');
        } finally {
            setLoading(false);
        }
    };

    // Delete

    const handleDelete = (id) => {
        showDeleteConfirm(id);
    };

    const deleteProduct = async (id) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/deleteEmployee.php`, { id });
            message.success(response.data.message);
            fetchEmployees();
        } catch (error) {
            message.error('Failed to delete employee');
        }
    };

    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this Brands?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => deleteProduct(id),
        });
    };

    const handleFilterApplied = (filteredData) => {
        setFilteredData(filteredData);
    };

    // Column
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id', 
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Resume',
            dataIndex: 'resume',
            key: 'resume',
            render: (text) => (
                <a href={`${API_BASE_URL}/${text}`} target='blank' download>
                    Download Resume
                </a>
            ),
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Date & Time',
            dataIndex: 'created_at',
            key: 'created_at',
            onCell: () => ({ style: { minWidth: 150 } }),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button type="link" danger onClick={() => handleDelete(record.id)}>
                    Delete
                </Button>
            ),
        },
    ];

    const isMobile = useSelector((state) => state.mobile.isMobile);


    return (
        <>
            <DateFilter data={employees} onFilterApplied={handleFilterApplied} />

            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />
        </>
    );
};

export default Employee;
