import React, { useState, useEffect } from 'react';
import { Select, DatePicker, Space } from 'antd';
const { Option } = Select;
const { RangePicker } = DatePicker;

const DateFilter = ({ data, onFilterApplied }) => {
    const [filter, setFilter] = useState('all');
    const [customRange, setCustomRange] = useState([null, null]);

    useEffect(() => {
        const filteredData = applyDateFilter(data);
        onFilterApplied(filteredData);
    }, [filter, customRange, data]);

    const applyDateFilter = (data) => {
        if (filter === 'all') return data;

        let startDate, endDate;
        const today = new Date();

        switch (filter) {
            case 'today':
                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
                break;

            case 'thisWeek':
                const firstDayOfWeek = today.getDate() - today.getDay();
                startDate = new Date(today.setDate(firstDayOfWeek));
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(today.setDate(firstDayOfWeek + 6));
                endDate.setHours(23, 59, 59, 999);
                break;

            case 'thisMonth':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
                break;

            case 'thisYear':
                startDate = new Date(today.getFullYear(), 0, 1);
                endDate = new Date(today.getFullYear(), 11, 31, 23, 59, 59);
                break;

            case 'custom':
                if (!Array.isArray(customRange) || customRange.length !== 2 || !customRange[0] || !customRange[1]) return data;
                startDate = new Date(customRange[0]);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(customRange[1]);
                endDate.setHours(23, 59, 59, 999);
                break;

            default:
                return data;
        }

        return data.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= startDate && createdAt <= endDate;
        });
    };

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const handleCustomRangeChange = (dates) => {
        setCustomRange(dates);
        setFilter('custom');
    };

    return (
        <Space direction="vertical" size="middle" style={{ marginBottom: '30px', display:'flex', alignItems:'end', justifyContent:'end', flexDirection:'row' }}>
            <Select value={filter} onChange={handleFilterChange} style={{ width: 200 }}>
                <Option value="all">None</Option>
                <Option value="today">Today</Option>
                <Option value="thisWeek">This Week</Option>
                <Option value="thisMonth">This Month</Option>
                <Option value="thisYear">This Year</Option>
                <Option value="custom">Custom Range</Option>
            </Select>
            {filter === 'custom' && (
                <RangePicker onChange={handleCustomRangeChange} />
            )}
        </Space>
    );
};

export default DateFilter;
