import { Card, Col, message, Row } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Home = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [inquery, setInquery] = useState([]);
  const [sell, setSell] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [fetchProducts, fetchBrands, fetchInquery, fetchSell] = await Promise.all([
        axios.get(`${API_BASE_URL}/getProducts.php`),
        axios.get(`${API_BASE_URL}/getBrands.php`),
        axios.get(`${API_BASE_URL}/getInquery.php`),
        axios.get(`${API_BASE_URL}/getSellFormData.php`),
      ]);
      setProducts(fetchProducts.data);
      setBrands(fetchBrands.data);
      setInquery(fetchInquery.data.data);
      setSell(fetchSell.data.data);
    } catch (error) {
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <div>
      <Card className='home-heading-cart'>
        <div className='overlay'></div>
        <h1>Welcome to Your Safar Cars Admin Dashboard</h1>
        <p>Manage your products, brands, inquiries, and more from here.</p>
      </Card>

      <Row className='home-cart-row' gutter={16} loading={loading} >
        <Col xs={24} sm={12} md={6} lg={6}>
          <Card title={'Total Products'}  >
            {products && products.length}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
          <Card title={'Total Brands'} >
            {brands && brands.length}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
          <Card title={'Total Inquery'}  >
            {inquery && inquery.length}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6}>
          <Card title={'Total Sell'}  >
            {sell && sell.length}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Home
