import { Button, Card, Form, Input, message, Typography } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login, setUserId } from '../redux/authSlice';

const { Title, Text } = Typography;


const Login = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openVerifyOtp, setOpenVerifyOtp] = useState(false);


    const handelLogin = async (values) => {
        const formData = new FormData();
        formData.append('identifier', values.identifier);
        formData.append('password', values.password);
        try {
            const response = await axios.post(`${API_BASE_URL}/adminLogin.php`, formData);
            if (response.data.status === 'success') {
                message.success(response.data.message);
                dispatch(login());
                dispatch(setUserId(response.data.userId))
                navigate('/');
            } else if (response.data.status === 'error' && response.data.message === 'Please verify your OTP before logging in.') {
                message.error('Otp Not Verifid Please Verify OTP and After Login');
                setOpenVerifyOtp(true);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('An error occurred while registering.');
        }
    };

    return (
        <>
            <div className="authform-container">
                <Card className="authform-card">
                    <Title level={3} style={{ textAlign: 'center' }}>Login</Title>

                    <Form form={form} onFinish={handelLogin} layout="vertical">

                        <Form.Item
                            name="identifier"
                            label="Email or Mobile"
                            rules={[{ required: true, message: 'Please enter your email or mobile!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                { required: true, message: 'Please input your password!' },
                                { min: 8, message: 'Password must be Minimum 8 digits!' }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Text type="secondary" style={{ textAlign: 'end', display: 'block' }}>
                            <Link to={'/forgotpassword'}>Forgot Password ?</Link>
                        </Text>

                        <Form.Item style={{ marginTop: '40px' }}>
                            <Button type="primary" htmlType="submit" block>Login</Button>
                        </Form.Item>

                    </Form>

                    {openVerifyOtp &&
                        <p>Your Accout Not Verified Please <Link to={'/verifyaccount'}>Verify Account</Link> </p>
                    }

                    <Text type="secondary">
                        You Are Not Ragistered ? <Link to={'/ragister'}>Ragister Now</Link>
                    </Text>

                </Card>
            </div>
        </>
    );
};

export default Login
