export const feature = [
    'ABS',
    'Keyless Start',
    'Power Windows',
    'Sunroof',
    'Integrated (In-Dash) Music System',
    'Leather Seats',
    'Audio Controls on Steering',
    'Power Steering',
    'Central Locking',
    'Rear AC Vent',
    'Child Safety Locks',
    'Airbags',
    'GPS Navigation System',
    'Rear Camera',
    'Rear Parking Sensor',
    'Apple Car Play',
    'Android Auto Audio System',
    'Heads Up Display',
    '360 Degree Camera',
    'Fancy Ambient Lighting',
    'Ventilated Seats',
    'Sunroof',
    'Ventilated Rear Seats',
    'Heated Steering Wheel',
    'Dual Zone Climate Control',
    'Rear Seat Infotainment',
    'Front Camera + Parking Sensors',
    'Digital Instrument Clusters',
    'TPMS (Tyre Pressure Monitoring System)',
    'Wireless Phone Charging',
    'Paddle Shifters',
    'Electrically Adjustable & Foldable ORVMs',
    'Electrically Adjustable ORVMs',
    'Keyless Entry',
    'Push Button Start/Stop',
    'Autonomous Emergency Braking',
    'Collision Avoidance Systems',
    'Headlamp Washers',
    'Switchable Drive Modes',
    'Auto Climate Control'
]