import React from 'react';
import { Descriptions, Image } from 'antd';
import { useSelector } from 'react-redux';


const ProductDetail = ({ selectedProduct }) => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const isMobile = useSelector((state) => state.mobile.isMobile);

    return (
        <div>
            <Descriptions bordered column={isMobile ? 1 : 2} size={isMobile ? 'small' : 'default'} labelStyle={{ width: '20%' }} contentStyle={{ width: '30%' }} >
                <Descriptions.Item label="ID">{selectedProduct.id}</Descriptions.Item>
                <Descriptions.Item label="Product Name">{selectedProduct.product_name}</Descriptions.Item>
                <Descriptions.Item label="Image" span={2}>
                    {selectedProduct.image.length > 1 ?
                        selectedProduct.image.map((item, index) => (
                            <Image key={index} src={`${API_BASE_URL}/${item}`} alt='product' width={100} />
                        )) :
                        <Image src={`${API_BASE_URL}/${selectedProduct.image}`} alt='product' width={100} />
                    }
                </Descriptions.Item>
                <Descriptions.Item label="Price">{selectedProduct.price}</Descriptions.Item>
                <Descriptions.Item label="Make">{selectedProduct.make}</Descriptions.Item>
                <Descriptions.Item label="Model">{selectedProduct.model}</Descriptions.Item>
                <Descriptions.Item label="Variant">{selectedProduct.variant}</Descriptions.Item>
                <Descriptions.Item label="Manufacturing Year">{selectedProduct.manufacturing_year}</Descriptions.Item>
                <Descriptions.Item label="Registration Year">{selectedProduct.registration_year}</Descriptions.Item>
                <Descriptions.Item label="Fuel Type">{selectedProduct.fuel_type}</Descriptions.Item>
                <Descriptions.Item label="Body Type">{selectedProduct.body_type}</Descriptions.Item>
                <Descriptions.Item label="KM Driven">{selectedProduct.km_driven}</Descriptions.Item>
                <Descriptions.Item label="Transmission">{selectedProduct.transmission}</Descriptions.Item>
                <Descriptions.Item label="Owners">{selectedProduct.owners}</Descriptions.Item>
                <Descriptions.Item label="Insurance Validity">{selectedProduct.insurance_validity}</Descriptions.Item>
                <Descriptions.Item label="Insurance Type">{selectedProduct.insurance_type}</Descriptions.Item>
                <Descriptions.Item label="RTO">{selectedProduct.rto}</Descriptions.Item>
                <Descriptions.Item label="Colour">{selectedProduct.colour}</Descriptions.Item>
                <Descriptions.Item label="Ground Clearance">{selectedProduct.ground_clearance}</Descriptions.Item>
                <Descriptions.Item label="Boot Space">{selectedProduct.boot_space}</Descriptions.Item>
                <Descriptions.Item label="Seating Capacity">{selectedProduct.seating_capacity}</Descriptions.Item>
                <Descriptions.Item label="Fuel Tank Capacity">{selectedProduct.fuel_tank_capacity}</Descriptions.Item>
                <Descriptions.Item label="Max Power">{selectedProduct.max_power}</Descriptions.Item>
                <Descriptions.Item label="Features" span={2}>
                    <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedProduct.features.map((item, index) => (
                            <li style={{ width: isMobile ? '100%' : '50%'   }} key={index}>
                                {item}
                            </li>
                        ))
                        }
                    </ul>
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default ProductDetail
