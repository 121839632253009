import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const AddBrandsForm = ({ fetchBrands, onClose }) => {

    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('brand_name', values.brand_name);
        if (values.image && values.image.length > 0) {
            formData.append('image', values.image[0].originFileObj);
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/addBrand.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            message.success(response.data.message);
            form.resetFields();
            fetchBrands();
            onClose(); // Close the modal after submission
        } catch (error) {
            message.error('Failed to submit data');
            console.error('There was an error!', error);
            setLoading(false);
        }finally{
            setLoading(false);
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Form
            form={form}
            name="brand_form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            id='add_brand'
        >
            <Form.Item
                label="Brand Name"
                name="brand_name"
                rules={[{ required: true, message: 'Please input the brand name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="image"
                label="Image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image!' }]}
            >
                <Upload name="image" listType="picture" maxCount={1} beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading} >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddBrandsForm;
