import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Table } from 'antd';
import AddProductForm from '../components/AddProductForm';
import axios from 'axios';
import EditProductForm from '../components/EditProductForm';
import ProductDetail from '../components/ProductDetail';
import { useSelector } from 'react-redux';

const Product = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [visible, setVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [loading, setLoading] = useState(true);


    // Add Product
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    // Get Product

    const fetchProduct = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getProducts.php`);
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchProduct();
    }, []);


    const handelOkDetails = () => {
        setShowDetails(false);
        setSelectedProduct(null);
    };

    const handleCancelDetails = () => {
        setShowDetails(false);
        setSelectedProduct(null);
    };

    const handleViewDetail = (product) => {
        setSelectedProduct(product);
        setShowDetails(true);
    };

    // Delete Product

    const handleDelete = (id) => {
        showDeleteConfirm(id);
    };

    const deleteProduct = async (id) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/deleteProduct.php`, { id });
            message.success(response.data.message);
            fetchProduct();
        } catch (error) {
            message.error('Failed to delete product');
        }
    };

    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this product?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => deleteProduct(id),
        });
    };

    // Edit Product

    const showEditModal = (product) => {
        setSelectedProduct(product);
        setEditVisible(true);
    };


    // Table Column
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id', 
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            onCell: () => ({ style: { minWidth: 200 } }),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {text.map((image, index) => (
                            <img
                                key={index}
                                src={`${API_BASE_URL}/${image}`}
                                alt="product"
                                width={50}
                                style={{ objectFit: 'cover', borderRadius: '4px' }}
                            />
                        ))}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Make (Brands)',
            dataIndex: 'make',
            key: 'make',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            key: 'variant',
            onCell: () => ({ style: { minWidth: 100 } }),
        },
        {
            title: 'Action',
            key: 'action',
            onCell: () => ({ style: { minWidth: 300 } }),
            render: (text, record) => (
                <>
                    <Button type="link" onClick={() => handleViewDetail(record)} >
                        View Detail
                    </Button>
                    <Button type="link" onClick={() => showEditModal(record)} >
                        Edit
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record.id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ]


    const isMobile = useSelector((state) => state.mobile.isMobile);



    return (
        <div>
            <Button type="primary" style={{ marginBottom: '20px', display: 'block', marginLeft: 'auto' }} className="open-modal-button" onClick={showModal}>
                Add New Product
            </Button>
            <Modal
                title="Add New Product"
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
                width={900}
            >
                <AddProductForm fetchProduct={fetchProduct} />
            </Modal>

            <Table
                dataSource={products}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />

            <Modal
                title="Product Details"
                open={showDetails}
                onOk={handelOkDetails}
                onCancel={handleCancelDetails}
                footer={null}
                width={900}
            >

                {selectedProduct &&
                    <ProductDetail selectedProduct={selectedProduct} />
                }

            </Modal>

            {selectedProduct && (
                <EditProductForm
                    visible={editVisible}
                    onClose={() => setEditVisible(false)}
                    product={selectedProduct}
                    fetchProduct={fetchProduct}
                />
            )}
        </div>
    );
};

export default Product;
