import React, { useEffect, useState } from "react";
import AddInvoiceForm from "../components/AddInvoiceForm";
import { Button, Form, message, Modal, Table } from "antd";
import axios from "axios";
import Invoice from "./Invoice";
import { useSelector } from "react-redux";

const InvoiceList = () => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [visible, setVisible] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  //   Get Invoice
  const getInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/getInvoices.php`);
      const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setInvoices(sortedData);
    } catch (error) {
      console.error("Error fetching invoices", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  //   Show Invoice
  const handleViewInvoice = (product) => {
    setSelectedInvoice(product);
    setViewInvoice(true);
  };

  // add and Edit Envoice
  const [form] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleEditInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setIsEditing(true);
    setVisible(true);
  };

  const onCloseForm = () => {
    setVisible(false);
    form.resetFields();
  }

  const onFinish = async (values) => {
    setLoadingBtn(true);
    const formData = new FormData();
    if (isEditing) {
      formData.append("id", selectedInvoice.id);
    }
    formData.append("customer_name", values.customer_name);
    formData.append("customer_email", values.customer_email);
    formData.append("customer_mobile", values.customer_mobile);
    formData.append("customer_address", values.customer_address);
    formData.append("product_name", values.product_name);
    formData.append("make", values.make);
    formData.append("model", values.model);
    formData.append("variant", values.variant);

    const manufacturingYear = values.manufacturing_year
      ? values.manufacturing_year.year()
      : "";
    formData.append("manufacturing_year", manufacturingYear);

    const registrationYear = values.registration_year
      ? values.registration_year.year()
      : "";
    formData.append("registration_year", registrationYear);

    formData.append("fuel_type", values.fuel_type);
    formData.append("km_driven", values.km_driven);
    formData.append("transmission", values.transmission);
    formData.append("owners", values.owners);

    const insuranceValidity = values.insurance_validity
      ? values.insurance_validity.format("YYYY-MM-DD")
      : "";
    formData.append("insurance_validity", insuranceValidity);

    formData.append("insurance_type", values.insurance_type);
    formData.append("rto", values.rto);
    formData.append("colour", values.colour);
    formData.append("chassis_no", values.chassis_no);
    formData.append("engin_no", values.engin_no);
    formData.append("price", values.price);
    formData.append("tax", values.tax);
    formData.append("other_price", values.other_price);
    formData.append("discount", values.discount);

    try {
      const endpoint = isEditing ? "/editInvoice.php" : "/addInvoice.php";
      const response = await axios.post(
        `${API_BASE_URL}${endpoint}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      message.success(response.data.message);
      getInvoices();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error("Failed to submit data");
      console.error("Error!", error);
    } finally {
      setLoadingBtn(false);
    }
  };

      // Delete Invoice

      const handleDelete = (id) => {
        showDeleteConfirm(id);
    };

    const deleteProduct = async (id) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/deleteInvoice.php`, { id });
            message.success(response.data.message);
            getInvoices();
        } catch (error) {
            message.error('Failed to delete product');
        }
    };

    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this invoice?',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => deleteProduct(id),
        });
    };


  // Table Column
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      onCell: () => ({ style: { minWidth: 80 } }),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      onCell: () => ({ style: { minWidth: 150 } }),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      onCell: () => ({ style: { minWidth: 150 } }),
    },
    {
      title: "Customer Email",
      dataIndex: "customer_email",
      key: "customer_email",
    },
    {
      title: "Customer Mobile",
      dataIndex: "customer_mobile",
      key: "customer_mobile",
    },
    {
      title: "Customer Address",
      dataIndex: "customer_address",
      key: "customer_address",
      onCell: () => ({ style: { minWidth: 200 } }),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      onCell: () => ({ style: { minWidth: 200 } }),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      key: "action",
      onCell: () => ({ style: { minWidth: 300 } }),
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleViewInvoice(record)}>
            View Invoice
          </Button>
          <Button type="link" onClick={() => handleEditInvoice(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
          Delete
          </Button>
        </>
      ),
    },
  ];

  const isMobile = useSelector((state) => state.mobile.isMobile);


  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: "20px", display: "block", marginLeft: "auto" }}
        className="open-modal-button"
        onClick={() => {
          setVisible(true);
          setIsEditing(false);
          setSelectedInvoice(null);
        }}
      >
        Add New Invoice
      </Button>

      <Modal
        title={isEditing ? "Edit Invoice" : "Add New Invoice"}
        open={visible}
        onOk={onCloseForm}
        onCancel={onCloseForm}
        footer={null}
        className="custom-modal"
        width={900}
      >
        <AddInvoiceForm
          onFinish={onFinish}
          form={form}
          loadingBtn={loadingBtn}
          selectedInvoice={selectedInvoice}
        />
      </Modal>

      <Table
        dataSource={invoices}
        columns={columns}
        rowKey="id"
        loading={loading}
        scroll={{ x: 800 }}
        size={isMobile ? 'small' : 'default'}
      />

      <Modal
        title="View Invoice"
        open={viewInvoice}
        onOk={() => setViewInvoice(false)}
        onCancel={() => setViewInvoice(false)}
        footer={null}
        className="custom-modal"
        width={850}
      >
        <Invoice selectedInvoice={selectedInvoice} />
      </Modal>
    </div>
  );
};

export default InvoiceList;
