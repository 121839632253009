import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import axios from 'axios';
import DateFilter from '../filter/DateFilter';
import { useSelector } from 'react-redux';

const Contacts = () => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    // Get Contacts
    useEffect(() => {
        fetchContacts();
    }, []);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/getContact.php`);
            if (response.data.status === 'success') {
                const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setContacts(sortedData);
                setFilteredData(sortedData);
            } else {
                message.error('Failed to fetch Contacts');
            }
        } catch (error) {
            message.error('Error fetching Contacts');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterApplied = (filteredData) => {
        setFilteredData(filteredData);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Date & Time',
            dataIndex: 'created_at',
            key: 'created_at',
        },
    ];

    const isMobile = useSelector((state) => state.mobile.isMobile);


    return (
        <>
            <DateFilter data={contacts} onFilterApplied={handleFilterApplied} />

            <Table
                dataSource={filteredData}
                columns={columns}
                rowKey="id"
                loading={loading}
                scroll={{ x: 800 }}
                size={isMobile ? 'small' : 'default'}
            />

        </>
    );
};

export default Contacts;
